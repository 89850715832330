html, body {
  margin: 0;
  padding: 0;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.emoji-index {
  display: flex;
  overflow-x: hidden;
  max-width: 75%;
}

.emoji-index:hover {
  overflow-x: auto;
}
.emoji-index {
  scrollbar-width: none;
}

.emoji-index::-webkit-scrollbar {
  height: 3px;
}
.emoji-index::-webkit-scrollbar-track {
  height: 4px;
}
.emoji-index::-webkit-scrollbar-thumb {
  background: #AAA;
}
.emoji-index::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.emoji-item {
  cursor: pointer;
  margin-right: 4px;
}

.emoji-item:last-child {
  margin-right: 0;
}

.not-supported {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  font-size: 24px;
}

.data-table-container {
  margin-top: 1rem;
  border-radius: 4px;
  border: 1px solid #AAAAAA;
  margin-bottom: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

table.data-table {
  display: table;
  width: 100%;
  max-width: 100%;
  min-width: 920px;
  background-color: transparent;
  border-collapse: collapse;
  text-indent: initial;
  border-spacing: 2px;
  font-size: 0.9rem;
}

table.data-table tbody tr {
  min-height: 36px;
}

table.data-table tbody tr:hover {
  background-color: #33333340 !important;
}

table.data-table tbody tr.odd {
  background-color: #EEEEEE;
}

table.data-table th {
  text-align: left;
  background-color: #104279;
  user-select: none;
  color: white;
  padding: 5px 7px;
}

table.data-table thead tr:last-child th {
  border-bottom: 1px solid #AAAAAA;
}
table.data-table td {
  text-align: left;
  padding: 5px 7px;
  border-left: 1px solid #AAAAAA;
  border-right: 1px solid #AAAAAA;
}
table.data-table tbody tr td:last-child {
  border-right: 0 solid #AAAAAA;
}
table.data-table tbody tr td:first-child {
  border-left: 0 solid #AAAAAA;
}

table.data-table .emoji-column {
  width: 425px;
}
table.data-table .emoji-index {
  display: inline-flex;
  max-width: 400px;
  overflow-x: auto;
  border-radius: 4px;
  background-color: white;
  padding: 2px 5px;
  box-shadow: 1px 1px 5px -2px #555555;
}

table.data-table .status-col, table.data-table .dismiss-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.discarded-reason {
  background-color: #333;
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 0.75em;
}

table.data-table .juror-edit {
  cursor: pointer;
}

table.data-table .juror-edit-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

table.data-table .juror-edit:hover {
  color: #5480B1;
}

table.data-table tr.matching-comments td {
  background-color: #DDD;
  color: #444;
  padding: 10px 15px;
}

.highlighted-match {
  display:inline;
}

.highlighted-wrapper .highlighted {
  padding: 2px;
  background-color: yellow;
  border-radius: 2px;
  color: black;
}

.table-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.table-pagination .page-selector, .table-pagination .page-size {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.data-table-toolset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 1px 10px;
  background-color: #EFEFEF;
  border-radius: 4px;
}

.data-table-toolset .search-container {
  flex: 0;
  flex-basis: 300px;
}

.data-table-toolset .toolset-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0;
  flex-basis: 200px;
}

.dropdown-menu {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.dropdown-menu-md {
  margin: -7px -5px;
  padding: 5px;
}

.dropdown-menu-sm {
  margin: -2px -3px -1px 0;
  padding: 2px;
}

.dropdown-menu:hover {
  background-color: #AAAAAA30;
}

.dropdown-menu-items {
  z-index: 100;
  position: absolute;
  cursor: default;
  background-color: white;
  box-shadow: 1px 1px 7px -3px black;
  border-radius: 6px;
  list-style: none;
  padding: 0;
  height: fit-content;
}

.dropdown-menu-item {
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 6px;
  font-size: .95em;
  white-space: nowrap;
}

.dropdown-menu-item:hover {
  background-color: #EFEFEF;
}

.dropdown-menu-item[aria-disabled='true'] {
  cursor: default;
}

.dropdown-menu-item[aria-disabled='true']:hover {
  background-color: inherit;
}

.dropdown-menu-item .divider {
  border: 0;
  border-top: 1px solid gray;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.dropdown-menu-item .divider-with-text {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.status-menu-icon {
  font-size: 18px !important;
}

.icon-lg .status-menu-icon {
  font-size: 20px !important;
}

.status-menu-text {
  padding-left: 8px;
}

.status-menu-text.active {
  font-weight: bold;
}

.thumbs-up-icon {
  color: #92C9C4;
}

.questions-icon {
  color: #FFE69B;
}

.thumbs-down-icon {
  color: #FFD6CE;
}

.menu-icon {
  color: #606060;
}
.subscription-cart {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 25px;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #5480B1;

    .desc {
      color: #9e9e9e;
    }

    .price {
      color: #5480B1;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .form-wrapper {
    min-width: 650px;
    height: 400px;
    position: relative;
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }

  .Form {
    padding: 15px;
    animation: fade 200ms ease-out;

    .total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #5480B1;

      h2, h3, h4 {
        margin-top: 0;
      }
    }
  }

  .FormGroup {
    margin-bottom: 20px;
    padding: 0;
    border-style: none;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06),
      0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 rgba(170, 183, 196, 0.2);
    border-radius: 4px;
    background-color: #fff;
  }

  .Coupon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 5px;
  }

  .CouponName {
    padding: 10px;
    will-change: opacity, transform;
    border-radius: 4px;
    border: 1px dashed #ddd;
    background-color: #fff;
    font-size: 16px;
    color: #666;
  }

  .FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid rgba(170, 183, 196, 0.2);
  }

  .FormRow:first-child {
    border-top: none;
  }

  .FormRowLabel {
    width: 30%;
    min-width: 70px;
    padding: 11px 10px;
    color: #104279;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: 1px solid rgba(170, 183, 196, 0.2);
    text-align: right;
  }

  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }

  .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #80671c;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }

  .FormRowInput {
    display: flex;
    flex: 1;
    font-size: 16px;
    //width: 100%;
    padding: 11px 15px 11px 0;
    color: #104279;
    background-color: transparent;
    animation: 1ms void-animation-out;
    text-indent: 10px;

    &::placeholder {
      color: #aab7c4;
    }
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  .ErrorMessage {
    color: darkred;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }

  .ErrorMessage svg {
    margin-right: 10px;
  }

  .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
  }

  .ResultTitle {
    color: #fff;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
  }

  .ResultMessage {
    color: #9cdbff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }
}
